import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import 'vuetify/dist/vuetify.min.css'
import '@/assets/sass/app.scss'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2A45CD',
        secondary: '#2C3242',
        accent: '#5CDB95',
      }
    },
  },
})