import { merge } from 'lodash'

const app = process.env.VUE_APP_PORTAL

class Vault {
  keep (info) {
    const data = merge(this.peek(), info)
    localStorage.setItem(app, JSON.stringify(data))
  }

  peek () {
    const data = localStorage.getItem(app)
    return data ? JSON.parse(data) : {}
  }

  take (key) {
    return this.peek()[key]
  }

  seal () {
    localStorage.removeItem(app)
  }
}

export default new Vault()