<template>
  <v-card 
    flat
    color="transparent"
  > 
    <v-card-title>
      <div>
        <h1 class="text-subtitle-1 font-weight-bold grey--text text--darken-1">
          {{ title }}
        </h1>
        <!-- <app-crumbs 
          :crumbs="breadcrumbs" 
        /> -->
      </div>
      
      <v-spacer></v-spacer>

      <slot name="actions">
        <v-btn
          v-if="ctaText"
          color="primary"
          class="ttn body-2"
          @click="$emit('action')"
        >
          {{ ctaText }}
        </v-btn>
      </slot>
    </v-card-title>
    
    <v-card-text>
      <slot name="content" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseView',

  props: {
    title: {
      type: String,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      required: true,
    },

    ctaText: {
      type: String,
    }
  },
}
</script>