import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('@/components/BaseLayout.vue'),
    children: [
      {
        path: 'products',
        name: 'supply-chain.products.index',
        component: () => import('@/views/ProductsView.vue')
      },
    ],
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
